import globalVariables from '../../globalVariable'

const gbVariable = globalVariables()
const permissionList = gbVariable.permissionsList

export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string; requiresAuth?: boolean } // Add requiresAuth to the meta object
  children?: INavigationRoute[]
  permission_name?: string | string[] // Optional permission name(s)
}

const getUserPermissions = (): string[] => {
  const permissions = localStorage.getItem('userPermissions')

  if (permissions) {
    // Split the string by commas to get an array of permissions
    return permissions.split(',').map((permission) => permission.trim())
  }

  return []
}

const hasPermission = (requiredPermissions: string | string[], userPermissions: string[]): boolean => {
  if (!requiredPermissions) return true
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions.some((permission) => userPermissions.includes(permission))
  }
  return userPermissions.includes(requiredPermissions)
}

const filterRoutes = (routes: INavigationRoute[], userPermissions: string[]): INavigationRoute[] => {
  return routes
    .map((route) => {
      // Filter children if present
      if (route.children) {
        route.children = filterRoutes(route.children, userPermissions)
      }
      if (!route.permission_name) return route
      // Check if the route or its children should be included
      if (hasPermission(route.permission_name, userPermissions)) {
        return route
      }
      return null
    })
    .filter((route) => route !== null) as INavigationRoute[]
}

const userPermissions = getUserPermissions()

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: filterRoutes(
    [
      {
        name: 'Access Control',
        displayName: 'menu.access-control',
        permission_name: permissionList.access_control,

        meta: {
          icon: 'supervisor_account',
        },
        children: [
          {
            name: 'roles',
            displayName: 'menu.roles',
            permission_name: permissionList.manage_roles,
          },
          {
            name: 'users',
            displayName: 'menu.users',
            permission_name: permissionList.users,
          },
        ],
      },
      {
        name: 'Dashboard',
        displayName: 'menu.dashboard',
        permission_name: permissionList.dashboard_management,
        meta: {
          icon: 'vuestic-iconset-dashboard',
        },
        children: [
          {
            name: 'dashboard',
            displayName: 'menu.dashboard',
            permission_name: permissionList.dashboard,
            // meta: {
            //   icon: 'vuestic-iconset-dashboard',
            // },
          },
          {
            name: 'statistics',
            displayName: 'menu.statistics',
            permission_name: permissionList.statistics,
            // meta: {
            //   icon: 'leaderboard',
            // },
          },
        ],
      },

      {
        name: 'Order Management',
        displayName: 'Orders',
        permission_name: permissionList.order_management,
        meta: {
          icon: 'shopping_cart',
        },
        children: [
          {
            name: 'orders-lists',
            displayName: 'Orders',
            permission_name: permissionList.order,
          },
          {
            name: 'unsettled-orders',
            displayName: 'menu.unsettled-orders',
            permission_name: permissionList.unSettledOrder,
          },
          {
            name: 'refund-orders',
            displayName: 'menu.refund-orders',
            permission_name: permissionList.refundOrder,
          },
          {
            name: 'complaints',
            displayName: 'menu.complaints',
            permission_name: permissionList.complaints,
          },
          {
            name: 'reviews',
            displayName: 'menu.reviews',
            permission_name: permissionList.reviews,
          },
          {
            name: 'Order Setting',
            displayName: 'menu.order-settings',
            permission_name: permissionList.orderSetting,
            meta: {
              icon: 'settings',
            },
            children: [
              {
                name: 'min-order-amount',
                displayName: 'menu.min-order-amount',
                permission_name: permissionList.minOrderAmount,
              },
              {
                name: 'min-order-time',
                displayName: 'menu.min-order-time',
                permission_name: permissionList.minOrderTime,
              },
              {
                name: 'min-payment-time',
                displayName: 'menu.min-payment-time',
                permission_name: permissionList.minPaymentTime,
              },
              {
                name: 'reward-faq',
                displayName: 'menu.reward-faq',
                permission_name: permissionList.rewardFaq,
              },
              {
                name: 'reward-status',
                displayName: 'menu.reward-status',
                permission_name: permissionList.rewardStatus,
              },
              {
                name: 'redeem-settings',
                displayName: 'menu.redeem-settings',
                permission_name: permissionList.redeemSettings,
              },
              {
                name: 'reward-user',
                displayName: 'menu.reward-user',
                permission_name: permissionList.rewardUser,
              },
            ],
          },
        ],
      },
      {
        name: 'Services',
        displayName: 'Services',
        permission_name: permissionList.service_management,
        meta: {
          icon: 'electrical_services',
        },
        children: [
          {
            name: 'services',
            displayName: 'menu.services',
            permission_name: permissionList.services,
          },
          {
            name: 'categories',
            displayName: 'menu.categories',
            permission_name: permissionList.category,
          },
          {
            name: 'sub-categories',
            displayName: 'menu.sub-categories',
            permission_name: permissionList.subCategory,
          },
          {
            name: 'line-items',
            displayName: 'menu.line-items',
            permission_name: permissionList.lineItem,
          },
          {
            name: 'slot-counts',
            displayName: 'menu.slot-counts',
            permission_name: permissionList.slot,
          },
          {
            name: 'locations',
            displayName: 'menu.locations',
            permission_name: permissionList.location,
          },
        ],
      },
      {
        name: 'Partners',
        displayName: 'Partners',
        permission_name: permissionList.partnerManagement,
        meta: {
          icon: 'person',
        },
        children: [
          {
            name: 'partners',
            displayName: 'menu.partners',
            permission_name: permissionList.partner,
          },
          {
            name: 'resources',
            displayName: 'menu.resources',
            permission_name: permissionList.resource,
          },
          {
            name: 'partner-wallet',
            displayName: 'menu.partner-wallet',
            permission_name: permissionList.partnerWallet,
          },
          {
            name: 'partner-payments',
            displayName: 'menu.partner-payments',
            permission_name: permissionList.partnerPayment,
          },
          {
            name: 'in-house-leaves',
            displayName: 'menu.in-house-leaves',
            permission_name: permissionList.inHouseLeave,
          },
          {
            name: 'in-house-salary',
            displayName: 'menu.in-house-salary',
            permission_name: permissionList.inHouseSalary,
          },
          {
            name: 'in-house-notice',
            displayName: 'menu.in-house-notice',
            permission_name: permissionList.inHouseNotice,
          },

          {
            name: 'sp-join-requests',
            displayName: 'menu.sp-join-requests',
            permission_name: permissionList.spJoinRequest,
          },
        ],
      },
      {
        name: 'payments',
        displayName: 'Payments',
        permission_name: permissionList.paymentManagement,
        meta: {
          icon: 'credit_card',
        },
        children: [
          {
            name: 'payment-methods',
            displayName: 'menu.payment-methods',
            permission_name: permissionList.paymentMethod,
          },
          // {
          //   name: 'pricing-plans',
          //   displayName: 'menu.pricing-plans',
          //   permission_name: permissionList.pricingPlan,
          // },
          // {
          //   name: 'billing',
          //   displayName: 'menu.billing',
          //   permission_name: permissionList.billing,
          // },
        ],
      },

      {
        name: 'Product Management',
        displayName: 'Products',
        permission_name: permissionList.productManagement,
        meta: {
          icon: 'inventory_2',
        },
        children: [
          {
            name: 'all-products',
            displayName: 'menu.products',
            permission_name: permissionList.product,
          },
          {
            name: 'product-request',
            displayName: 'menu.product-request',
            permission_name: permissionList.productRequest,
          },
        ],
      },
      {
        name: 'marketing',
        displayName: 'Marketing',
        permission_name: permissionList.marketingManagement,
        meta: {
          icon: 'campaign',
        },
        children: [
          {
            name: 'email',
            displayName: 'Email',
            permission_name: permissionList.emailMarketing,
            children: [
              {
                name: 'campaigns',
                displayName: 'Campaigns',
                permission_name: permissionList.campaign,
              },
              {
                name: 'templates',
                displayName: 'Templates',
                permission_name: permissionList.template,
              },
              {
                name: 'audiences',
                displayName: 'Audiences',
                permission_name: permissionList.audience,
              },
            ],
          },
          {
            name: 'email-promotions',
            displayName: 'Email Promotions',
            permission_name: permissionList.emailPromotion,
          },
          {
            name: 'push-notification',
            displayName: 'menu.push-notification',
            permission_name: permissionList.pushNotification,
          },
          {
            name: 'future-notification',
            displayName: 'menu.future-notification',
            permission_name: permissionList.futureNotification,
          },

          {
            name: 'schedule-notification',
            displayName: 'menu.schedule-notification',
            permission_name: permissionList.scheduleNotification,
          },
          {
            name: 'feed',
            displayName: 'menu.feed',
            permission_name: permissionList.feed,
          },
          {
            name: 'promo-codes',
            displayName: 'menu.promo-codes',
            permission_name: permissionList.promoCode,
          },
          {
            name: 'hot-deals',
            displayName: 'menu.hot-deals',
            permission_name: permissionList.hotDeal,
          },
          {
            name: 'card-bins',
            displayName: 'menu.card-bins',
            permission_name: permissionList.cardBin,
          },
          {
            name: 'banners',
            displayName: 'menu.banners',
            permission_name: permissionList.banner,
          },
          {
            name: 'reward-point',
            displayName: 'menu.reward-point',
            permission_name: permissionList.banner,
          },
        ],
      },
      {
        name: 'reports',
        displayName: 'Reports',
        permission_name: permissionList.reportManagement,
        meta: {
          icon: 'summarize',
        },
        children: [
          {
            name: 'daily-reports',
            displayName: 'menu.reports',
            permission_name: permissionList.report,
          },
          {
            name: 'area-wise-reports',
            displayName: 'menu.area-wise-reports',
            permission_name: permissionList.areaReport,
          },
          {
            name: 'line-item-revenue',
            displayName: 'menu.line-item-revenue',
            permission_name: permissionList.lineItemRevenue,
          },
          {
            name: 'sp-wise-order-count',
            displayName: 'menu.sp-wise-order-count',
            permission_name: permissionList.spOrder,
          },
          {
            name: 'old-reports',
            displayName: 'menu.old-reports',
            permission_name: permissionList.oldReport,
          },
        ],
      },
      {
        name: 'cs reports',
        displayName: 'menu.cs-reports',
        permission_name: permissionList.csReport,
        meta: {
          icon: 'summarize',
        },
        children: [
          {
            name: 'sp-route',
            displayName: 'menu.sp-route',
            permission_name: permissionList.spRoute,
          },
          {
            name: 'otps',
            displayName: 'menu.otps',
            permission_name: permissionList.otp,
          },
        ],
      },
      {
        name: 'Content Management',
        displayName: 'Contents',
        permission_name: permissionList.contentManagement,
        meta: {
          icon: 'display_settings',
        },
        children: [
          {
            name: 'content-home',
            displayName: 'Home',
            permission_name: permissionList.home,
          },
          {
            name: 'content-about',
            displayName: 'About',
            permission_name: permissionList.about,
          },
          {
            name: 'content-service',
            displayName: 'Service',
            permission_name: permissionList.service,
          },
          {
            name: 'content-offer',
            displayName: 'Offer',
            permission_name: permissionList.offer,
          },
          {
            name: 'ticker',
            displayName: 'menu.ticker',
            permission_name: permissionList.ticker,
          },
          {
            name: 'mou-partners',
            displayName: 'menu.mou-partners',
            permission_name: permissionList.mouPartner,
          },
          {
            name: 'future-of-beauty',
            displayName: 'menu.future-of-beauty',
            permission_name: permissionList.futureOfBeauty,
          },
          {
            name: 'promotions',
            displayName: 'menu.promotions',
            permission_name: permissionList.promotions,
          },
          {
            name: 'employees',
            displayName: 'Employees',
            permission_name: permissionList.employee,
          },
          {
            name: 'payment-channel',
            displayName: 'menu.payment-channel',
            permission_name: permissionList.paymentChannel,
          },
          {
            name: 'faqs',
            displayName: 'menu.faqs',
            permission_name: permissionList.faq,
          },
        ],
      },
      {
        name: 'preferences',
        displayName: 'My Account',
        permission_name: permissionList.preference,
        meta: {
          icon: 'manage_accounts',
        },
      },
    ] as INavigationRoute[],
    userPermissions,
  ),
}
