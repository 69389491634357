<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="mainMenuState" multiple>
      <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="route.name" v-model="routeStates[index]">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :to="route.children ? undefined : { name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="20px"
                :color="iconColor(route)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
                <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body="{}">
          <!-- Nested Accordion for the first sub-menu level -->
          <VaAccordion v-model="subMenuStates[index]" multiple>
            <VaCollapse
              v-for="(childRoute, index2) in route.children"
              :key="childRoute.name"
              v-model="subMenuStates[index][index2]"
            >
              <template #header="{ value: isSubSubmenuCollapsed }">
                <VaSidebarItem
                  :to="childRoute.children ? undefined : { name: childRoute.name }"
                  :active="isActiveChildRoute(childRoute)"
                  :active-color="activeColor"
                  :text-color="textColor(childRoute)"
                  :aria-label="`Visit ${t(childRoute.displayName)}`"
                  hover-opacity="0.10"
                >
                  <VaSidebarItemContent class="py-3 pr-2 pl-11">
                    <VaSidebarItemTitle class="leading-5 font-semibold">
                      {{ t(childRoute.displayName) }}
                      <VaIcon
                        v-if="childRoute.children"
                        class="float-right pr-3"
                        :name="arrowDirection(isSubSubmenuCollapsed)"
                        size="20px"
                      />
                    </VaSidebarItemTitle>
                  </VaSidebarItemContent>
                </VaSidebarItem>
              </template>
              <template #body>
                <!-- Sub-submenu items -->
                <div v-if="childRoute.children" class="pl-6">
                  <VaSidebarItem
                    v-for="(subChildRoute, index3) in childRoute.children"
                    :key="subChildRoute.name"
                    :active-color="activeColor"
                    :to="{ name: subChildRoute.name }"
                    :active="isActiveChildRoute(subChildRoute)"
                    :text-color="textColor(subChildRoute)"
                    :aria-label="`Visit ${t(subChildRoute.displayName)}`"
                    hover-opacity="0.10"
                  >
                    <VaSidebarItemContent class="py-3 pr-2 pl-14">
                      <VaSidebarItemTitle class="leading-5 font-semibold">
                        {{ t(subChildRoute.displayName) }}
                      </VaSidebarItemTitle>
                    </VaSidebarItemContent>
                  </VaSidebarItem>
                </div>
              </template>
            </VaCollapse>
          </VaAccordion>
        </template>
      </VaCollapse>
    </VaAccordion>
  </VaSidebar>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],
  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()

    // Main state for the sidebar visibility
    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    // State for each top-level route
    const mainMenuState = ref<boolean[]>([])
    const routeStates = ref(navigationRoutes.routes.map(() => false))

    // State for each sub-menu and sub-submenu
    const subMenuStates = ref(
      navigationRoutes.routes.map((route) => (route.children ? route.children.map(() => false) : [])),
    )

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }
      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    // Dynamic styling
    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    // Set initial expanded state based on route
    const setActiveExpand = () => {
      mainMenuState.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route))
    }

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    // Ensure all necessary properties are returned
    return {
      writableVisible,
      sidebarWidth,
      mainMenuState,
      routeStates,
      subMenuStates,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      color,
      activeColor,
      iconColor,
      textColor,
      arrowDirection,
    }
  },
})
</script>
